import { Box } from '@mui/system';
import React, { useEffect } from 'react'; // component props interface
import { CardActions, CardContent, CardMedia, Grid, Skeleton, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalOfferOutlined, ArrowRight } from '@mui/icons-material';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';


const StyledBox = styled(Box)(({
	theme
}) => ({
	borderRadius: 4,
	padding: 10,
	cursor: 'pointer',
	transition: 'all 250ms ease-in-out',
	boxShadow: theme.shadows[3],
	position: 'relative',
	background: 'white',
	minHeight: 300,
	padding: 0
}));


const ArticleCard2 = ({ article, priorityImage }) => {
	let [claimReview, setClaimReview] = useState(article.Claim.ClaimReviews[0]);
	const theme = useTheme()

	useEffect(() => {
		if (article.Claim.ClaimReviews.length > 1) {
			const c = article.Claim.ClaimReviews.find(review => review.isPriority === true)
			if (c) setClaimReview(c)
		}
	}, [])

	return <Link href={`/factchecks/${article.id}`}>
		<StyledBox style={{ boxShadow: theme.shadows[3], background: 'white', fontSize: 16 }}>
			{
				claimReview.retractImage ? <>
					<CardMedia
						sx={{ height: 200, backgroundSize: 'contain'}}
						image={`/assets/images/branding/logo/logo-footer.png`}
					/>

				</>
					:
					<>
						<CardMedia
							sx={{ height: 200 }}
							image={`https://cdn.decheckers.be/images/${claimReview.id}`}
						/>
					</>
			}

			<CardContent sx={{ padding: 0, }}>
				<Box padding={1} sx={{ height: 105 }}  >
					<Grid container spacing={1} sx={{ height: '100%' }}>
						<Grid item lg={12}>
							<h2 style={{ fontSize: "1em", fontFamily: "Poppins", padding: '10px', marginTop: 0 }} color={"primary.900"}>
								{!article ? <Skeleton height="50px" variant="text"></Skeleton> : article.title ?
									<span style={{ userSelect: "none" }}>
										{
											article.title.toLowerCase().includes('factcheck') ? <span style={{ color: theme.palette.primary.main }}>Factcheck</span> : <span style={{ color: theme.palette.primary.main }}>Factcheck: </span>
										}

										<span style={{ fontWeight: 500, color: '#525252' }} alt={article.title}>
											{
												article.title.length > 80 ? article.title.replace('Factcheck', '').slice(0, 80) + '...' : article.title.replace('Factcheck', '')
											}
										</span>
									</span> : "Titel niet beschikbaar"}
							</h2>
						</Grid>
					</Grid>
				</Box>


			</CardContent>
			<CardActions>
				<Grid container justifyContent={'space-between'}>
					<Grid item>
						<div style={{
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
							marginLeft: 10,
						}}>
							<LocalOfferOutlined sx={{ marginRight: 1, fontSize: 'small' }} />
							<span style={{ fontSize: 'small' }}>{article.Claim.ClaimCategories[0].nl}</span>
						</div>
					</Grid>

					<Grid item>
						<div style={{
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
						}}>
							<span>Lees meer</span>
							<ArrowRight sx={{ marginLeft: 1, fontSize: 'small' }} />
						</div>
					</Grid>

				</Grid>
			</CardActions>
		</StyledBox>
	</Link>
};

export default ArticleCard2;